import * as React from 'react';
import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { Layout } from '../components/partials/common';
import { SEO } from '../components/seo';
import Button from '../components/ui/button';

import { LocalizedPageContext } from '../utils/types';
import images from '../utils/store/images';
import { ImageGallery } from '../components/ui/imageGallery';

const ThankYouPage: FC<{
  pageContext: LocalizedPageContext;
}> = ({ pageContext }) => {
  const { lang, alternateUrls, key } = pageContext;

  const { t } = useTranslation(['ssu_pos', 'general']);
  const thanks: any = t('thanks', {
    returnObjects: true,
  });
  const { emailProvider } = images;

  const data = pageContext.i18nResources[lang][key];
  return (
    <Layout
      pageContext={pageContext}
      secondNav
      wrapperClasses="secondary_page"
      mainClasses="text-centered is-centered"
    >
      <SEO
        lang={lang}
        title={t('meta.title')}
        description={t('meta.description')}
        alternateUrls={alternateUrls}
      />
      <section className="is-centered text-centered" id="pos_thanks_section">
        <div className="container inner_section">
          <h1 className="title-medium title text-black">{thanks.title}</h1>
          <p> {thanks.text}</p>
          <br />
          <p className="bottom_paragraph"> {thanks.text_2}</p>
          <div className="image-container image-list columns">
            {Object.keys(emailProvider).map((k: any) => {
              return (
                <a href={emailProvider[k].url} target="_blank" rel="noreferrer">
                  <ImageGallery
                    alt="email provider"
                    name={emailProvider[k].icon}
                    className="mail_provider column"
                    key={k}
                  />
                </a>
              );
            })}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ThankYouPage;
